<template>
  <div>
    <span v-if="user_status == 'true'">
    <CCard>
      <CCardHeader class="text-center">
        <CIcon name="cil-grid" /><strong>Report</strong>
        <CRow class="mt-3">
          <CCol class="col-md-6 col-12">
            <CCard class="p-2">
              <CRow>
                <CCol>
                <label class="mt-2">Date Range</label>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                    {{ changeDateFormateForCalender(startDate)}}   <b>-</b>  {{ changeDateFormateForCalender(endDate) }}
                  </span>
                </CCol>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
                
              >
              <date-range-picker 
                :from="date_data.endDate"
                :to="date_data.startdate"
                :future="false"
                :panel="date_data.panel"
                :showControls="false" 
                @select="update"/>
              <template #footer>
                <CButton @click="date_modal = false" color="danger">Close</CButton>
              </template>
              </CModal>
              </CRow>
              <CRow>
                <CCol class="mt-2 col-md-8">
                  <label class="ml-2 mt-2">TimeZone</label>
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    track-by="value"
                    label="text"
                    :multiple="false"
                    :options="timezoneopt"
                    :preselect-first="true"
                    :preserve-search="false"
                    placeholder="Pick some"
                    v-model="filter_dash.timezoneopt"
                  >
                  </multiselect>
                  </CCol>
                  <CCol class="mt-2">
                  <label class="ml-2 mt-2">View By</label>
                  <CSelect
                    required
                    :value.sync="filter_dash.granularity"
                    :options="granularity_opt"
                  />
                </CCol>
              </CRow>
              <CRow class="mt-2">
                <CCol class="col-md-12">
                  <label>Group By</label>
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    track-by="value"
                    label="text"
                    :multiple="true"
                    :options="projectionopt"
                    :preselect-first="true"
                    :preserve-search="false"
                    placeholder="Pick some"
                    v-model="filter_dash.projectionopt"
                  >
                  </multiselect>
                </CCol>
              </CRow>
              <CRow class="pb-5">
                <CCol class="col-md-12">
                <label class="ml-2 mt-2">Dimension</label>
                <multiselect
                  :limit="2"
                  :clear-on-select="false"
                  :close-on-select="false"
                  track-by="value"
                  label="text"
                  :multiple="true"
                  :options="metricsopt"
                  :preselect-first="true"
                  :preserve-search="true"
                  placeholder="Pick some"
                  v-model="filter_dash.metricsopt"
                >
                  <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="filter_dash.metrics.length &amp;&amp; !isOpen">{{ filter_dash.metrics.length }} options selected</span></template> -->
                </multiselect>
                </CCol>
              </CRow>
            </CCard>
          </CCol>
          <CCol class="col-md-6 col-12">
            <CCard class="p-3">
              <CRow class="col-md-12 col-12">
                <label>Filter</label>
              </CRow>
              <!-- Filter1 -->
              <CRow class="">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention()"
                    placeholder="Pick some"
                    v-model="dimfilter"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt"
                    :taggable="true"
                    @tag="addTag1"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_1 == false"
                >
                  <span @click="Clone('add', 1)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter2 -->
              <CRow class="mt-2" v-show="filter_fields.filter_1">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention1()"
                    placeholder="Pick some"
                    v-model="dimfilter_f1"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f1"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f1"
                    :taggable="true"
                    @tag="addTag2"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_2 == false"
                >
                  <span @click="Clone('add', 2)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 1)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter3 -->
              <CRow class="mt-2" v-show="filter_fields.filter_2">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention2()"
                    placeholder="Pick some"
                    v-model="dimfilter_f2"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f2"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f2"
                    :taggable="true"
                    @tag="addTag3"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_3 == false"
                >
                  <span @click="Clone('add', 3)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 2)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter4 -->
              <CRow class="mt-2" v-show="filter_fields.filter_3">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention3()"
                    placeholder="Pick some"
                    v-model="dimfilter_f3"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f3"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f3"
                    :taggable="true"
                    @tag="addTag4"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_4 == false"
                >
                  <span @click="Clone('add', 4)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      cnewofferlass="img-fluid ml-1"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 3)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter5 -->
              <CRow class="mt-2" v-show="filter_fields.filter_4">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention4()"
                    placeholder="Pick some"
                    v-model="dimfilter_f4"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f4"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f4"
                    :taggable="true"
                    @tag="addTag5"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>

                <CCol class="col-md-1 col-12 mt-2">
                  <span @click="Clone('remove', 4)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1"> </CCol>
              </CRow>
            </CCard>
          </CCol>
        </CRow>
        <CRow class="">
          <CCol class="text-cente">
            <CButton class="col-md-2 col-12" color="success" @click="OnSubmit()"
              >Get Report</CButton
            >
            <vue-csv-downloader
              class="ml-md-4"
              :data="items"
              :fields="csvFields"
            >
              <CButton class="col-md-2 col-12 mt-md-0 mt-2" color="info"
                >CSV</CButton
              >
            </vue-csv-downloader>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="text-center">
        <div v-if="loading == true">Loading . . .</div>
        <div v-else>
          <CRow>
            <CCol>
              <CRow class="col-md-12">
                <label>Filter :</label>
                <CInput
                  class="col-md-8"
                  @keyup="customFilter"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <label>Total Rows : {{ totalRows }}</label>
              </CRow>
            </CCol>
            <CCol>
              <div class="card-header-actions">
                <small class="text-muted">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="perPage"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="checkPage()"
                    placeholder="PerPage"
                    v-model="tableperpage"
                  ></multiselect
                ></small>
              </div>
            </CCol>
          </CRow>
          <!-- <span  v-if="loading">Loading ...</span>
        <span v-else> -->
          <div class="table_div">
          <CDataTable
            :sorterValue.sync="sortDesc"
            :items="items"
            :fields="fields"
            :dark="false"
            :items-per-page="tableperpage"
            pagination
            :sorter='{ external: true, resetable: true }'
            hover
            striped
            border
            small
            fixed
            column-filter
          >
            <template #date="{ item }">
              <td>
                {{ changeDateFormate(item.date) }}
              </td>
              <!-- {{item.date}} -->
            </template>
            <!-- column-filter -->
            <template #campname="{ item }"
              ><td>
                <span
                >
                  {{ item.campname }}</span
                >
              </td></template
            >

            <template #offerid="{ item }"
              ><td>
                <span class="link" @click="getofferData(item.offerid)">{{
                  offer_name[item.offerid]
                }}</span>
              </td></template
            >

            <template #tsid="{ item }"
              ><td>
                <span class="link" @click="getTsData(item.tsid)">
                {{ traffic_source_name[item.tsid] }}
                </span>
                </td></template
            >

            <template #offerrev="{ item }"
              ><td>{{ item.offerrev.toFixed(2) }}</td></template
            >

            <template #camprev="{ item }"
              ><td>{{ item.camprev.toFixed(2) }}</td></template
            >
          </CDataTable>
          </div>
        </div>
      </CCardBody>
    </CCard>

    </span>
    <span v-else>
      <CCard class="col-md-12">
         <h4 style="color:red;">Your Account is not activated</h4>
        <label>please contact to Manager</label>
      </CCard>
    </span>
  </div>
</template>


<script>
import DashboardService from "../../services/dashboardController";
import ManagerService from "../../services/managerControlService";
import OfferService from "../../services/offerControlService";
import TsService from "../../services/trafficsourceControllservice";
import Multiselect from "vue-multiselect";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueCsvDownloader from 'vue-csv-downloader';
import TimeZone from "../../services/timezones";
var timezonelist = TimeZone
export default {
  name: "Dashboard",
  components: {
    Multiselect,
    DateTimePicker,
    VueCsvDownloader,
    TimeZone
  },
  data() {
    return {
      date_data:{startdate:null,enddate:null,panel:null},
      date_modal:false,
      sortDesc:{},
      all_data:[],
      filter: '',
      totalRows:0,
      filter_fields:{filter_1 :false,filter_2:false,filter_3:false,filter_4:false},
      tableperpage:20,
      perPage:[20,50,100],
      isactive_revrisk:false,
      pageOptions: [10, 15],
      items: [],
      click_trigger: {},
      user: "",
      user_status: false,
      useremail: "",
      camp_list: [],
      offer_name: [],
      traffic_source_name: [],
      timezoneopt:timezonelist.map((t)=>{return {value:t.tzCode,text:t.label}}),
      filter_dash: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        dim: {},
        granularity: "daily",
        projection: [],
        metrics: [],
        useremail: "",
        projectionopt:[],
        metricsopt:[{ value: "browse", text: "Browser" },
        { value: "render", text: "Render" },
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Accepted" },
        { value: "discarded", text: "Discarded" },
        { value: "rejected", text: "Rejected" },
        { value: "converted", text: "Converted" },],
        timezoneopt:{ text: '(GMT+00:00) London', value: 'Europe/London' }
      },
      startDate: new Date(),
      endDate: new Date(),
      click_risk: { blockedClick: null },
      conversion_risk: {},
      fields: [],
      dimfilter: "",
      dimfilter_f1 :"",
      dimfilter_f2 :"",
      dimfilter_f3 :"",
      dimfilter_f4 :"",
      projectionopt: [
        { value: "campname", text: "Campaign" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "TrafficSource" },
        { value: "country", text: "Country" },
        { value: "browser", text: "Browser" },
        { value: "os", text: "Operating System" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        { value: "packageid", text: "Package Id"},
        { value: "frauslvl", text: "Fraud Level" },
        { value: "frausLvlForUser", text: "User Fraud Level" },
        { value: "external_id", text: "External Id" },
        { value: "carrier", text: "Carrier" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
      metricsopt: [],
      dimentionopt: [],
      granularity_opt: ["daily", "hourly","monthly"],
      dimentionopt2: [],
      dimentionopt2_f1: [],
      dimentionopt2_f2: [],
      dimentionopt2_f3: [],
      dimentionopt2_f4: [],
      dimopt: [],
      dimopt_f1: [],
      dimopt_f2: [],
      dimopt_f3: [],
      dimopt_f4: [],
      url: "",
      zeroms_url: "",
      camp_url: false,
      url_param: {
        sub1: "sub1",
        sub2: "sub2",
        sub3: "sub3",
        sub4: "sub4",
        sub5: "sub5",
        sub6: "sub6",
        sub7: "sub7",
        sub8: "sub8",
        sub9: "sub9",
        sub10: "sub10",
        sub11: "sub11",
        sub12: "sub12",
      },
      collapse: false,
      useremail:'',
      csvFields: [],
      change_date : false,
      loading:false,
      projectionopt_val:[],
      metricsopt_val:[],
      filter_count:0,
      sdk_url:'',
    };
  },
  watch: {
    sortDesc: function (val) {
      this.runQuery(val)
    },
  },
  async mounted() {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") == "null"
    ) {
      // alert("Please Enter User Name and Password");
      this.$router.push("/pages/login");
    } else {
      if(localStorage.getItem("m_table") != null){
      this.tableperpage = parseInt(localStorage.getItem("m_table"));
      }
      this.user = window.localStorage.getItem("user");
      this.user_status = window.localStorage.getItem("status");
      this.useremail = window.localStorage.getItem("email");
      this.filter_dash.useremail = this.useremail;
      this.filter_dash.projection = ['campname']
      this.projectionopt_val = [{ value:"campname",text:"Campaign" }]
      if(this.user_status == 'true'){
      this.dateFilter({startDate:new Date(this.startDate.setHours(0,0)),endDate:new Date(this.endDate.setHours(23,59))});
      this.getDimentions();
      this.runQuery();
      
        let response = await ManagerService.camplist({
          useremail: this.useremail,
        });
        if(response.result){
          this.camp_list = response.message.campaign;
          this.offer_name = response.message.offer;
          this.traffic_source_name = response.message.trafficsource;
        }
      }
    }
  },
  methods: {
    changeDateFormateForCalender(d){
      let dateToFormate
      if(typeof d != "string") {
        dateToFormate = new Date(d);
      } else {
        let dd = d.split("T")[0]
        dateToFormate = new Date(dd);
      } 
     return dateToFormate.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
    },
    update(values) {
      this.date_data.startdate = values.from
      this.date_data.enddate = values.to
      this.date_data.panel = values.panel
      this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
      this.date_modal = false;
    },
    customFilter() {
      let search = this.filter.toString().toLowerCase()
      if(search != ''){
      let f_data = this.all_data.filter(row => row.campname.match(search))
      if(f_data){return this.items = f_data}
      }
      else{return this.items = this.all_data}
    },
    Clone(txt,count){
      if(txt == 'add'){
      switch(count){
        case 1 : this.filter_fields.filter_1 = true
        break;
        case 2 : this.filter_fields.filter_2 = true
        break;
        case 3 : this.filter_fields.filter_3 = true
        break;
        case 4 : this.filter_fields.filter_4 = true
        break;
      }
      }
      if(txt == 'remove'){
        switch(count){
        case 1 : this.filter_fields.filter_1 = false
                 this.dimfilter_f1 = ""
                 this.dimopt_f1 = []
                 this.dimentionopt2_f1 = []
        break;
        case 2 : this.filter_fields.filter_2 = false
                 this.dimfilter_f2 = ""
                 this.dimopt_f2 = []
                 this.dimentionopt2_f2 = []
        break;
        case 3 : this.filter_fields.filter_3 = false
                 this.dimfilter_f3 = ""
                 this.dimopt_f3 = []
                 this.dimentionopt2_f3 = []
        break;
        case 4 : this.filter_fields.filter_4 = false
                 this.dimfilter_f4 = ""
                 this.dimopt_f4 = []
                 this.dimentionopt2_f4 = []
        break;
      }
      }
    },
    getLabel(key){
      return labels[key]
    },
    changeDateFormate(d){
      var p = new Date(d);
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(p);
      let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(p);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(p);
      let hh = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(p);
      var dd
      if(this.change_date){
       dd = `${ye} ${mo} ${da} , ${hh}`;
      }
      else{ dd = `${ye} ${mo} ${da}` }
      return  dd
    },
    checkPage(){
      localStorage.setItem("m_table", this.tableperpage);
    },
    async getDimentions(){
        this.projectionopt = []
        var response = await ManagerService.getoptions();
        if(response.result){
          response.message.dimention.map((r)=>{
          this.dimentionopt.push( { value: r, text: r })
          this.projectionopt.push( { value: r, text: r })
        });
        
        response.message.metrics.map((v)=>{
          this.metricsopt.push({ value: v, text: v });
        })
        }
    },
    async getofferData(id) {
      try {
        var data = { useremail: this.useremail, offerid: id };
        let response = await OfferService.get(data);
        this.$root.$data.offer = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/offer/newoffer");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    async getTsData(id) {
      try {
        var data = { useremail: this.useremail, offerid: id };
        let response = await TsService.tsget(data);
        this.$root.$data.traffic = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/ts/newts");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    addTag1(newTag) { this.dimopt.push({value:newTag,text:newTag}); },
    addTag2(newTag) { this.dimopt_f1.push({value:newTag,text:newTag}); },
    addTag3(newTag) { this.dimopt_f2.push({value:newTag,text:newTag}); },
    addTag4(newTag) { this.dimopt_f3.push({value:newTag,text:newTag}); },
    addTag5(newTag) { this.dimopt_f4.push({value:newTag,text:newTag}); },
    dateFilter(data) {
      this.filter_dash.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.filter_dash.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate
      this.endDate = data.endDate
      // this.filter_dash.sdate = (new Date(data.startDate)).toISOString();
      // this.filter_dash.edate = (new Date(data.endDate)).toISOString()
    },
    OnSubmit(){
      this.filter_dash.projection = [];
      this.runQuery()
    },
    runQuery: async function(sort) {
      this.startDate = new Date(this.startDate.setHours(0,0))
      this.endDate = new Date(this.endDate.setHours(23,59))
      this.loading = true
      this.change_date = false
      if(this.filter_dash.granularity == "hourly"){
        this.change_date = true
      }
      try {
        this.filter_dash.dim = {}
        this.pushTodim();
        this.filter_dash.projectionopt.map((m) => {
          this.filter_dash.projection.push(m.value);
          this.projectionopt_val.push({value:m.value, text: m.text})
        });
        this.filter_dash.metrics = []
        this.filter_dash.metricsopt.map((m) => {
          this.filter_dash.metrics.push(m.value);
          this.metricsopt_val.push({value:m.value, text: m.text})
        });
        var response
        if(sort){
          this.filter_dash.sortBy = sort.column
          this.filter_dash.sortAescDir = sort.asc
        }
        response = await ManagerService.managerdash(this.filter_dash);
      
        if(response.result){
        this.loading = false
        this.items = [];
        this.items = response.message.tableData;
        this.all_data = response.message.tableData;
        this.sortOptions();
        this.metricsopt_val=[]
        this.projectionopt_val = []
        this.totalRows = this.items.length;
        }
      } catch (e) {
        console.log("Error in getting dashboard data:");
        console.log(e);
      }
    },
    pushTodim() {
      // let cake = this.filter_dash.dim;
      if(this.dimfilter != null){
      if (this.dimfilter.value) {
        this.filter_dash.dim[this.dimfilter.value] = this.dimopt.map(r=>r.value);
      } else {
        this.filter_dash.dim = {};
      }
      if (this.dimfilter_f1.value) {
        this.filter_dash.dim[this.dimfilter_f1.value] = this.dimopt_f1.map(r=>r.value);
      }
      if (this.dimfilter_f2.value) {
        this.filter_dash.dim[this.dimfilter_f2.value] = this.dimopt_f2.map(r=>r.value);
      }
      if (this.dimfilter_f3.value) {
        this.filter_dash.dim[this.dimfilter_f3.value] = this.dimopt_f3.map(r=>r.value);
      }
      if (this.dimfilter_f4.value) {
        this.filter_dash.dim[this.dimfilter_f4.value] = this.dimopt_f4.map(r=>r.value);
      }
      }
    },
    capitalize(s)
    {
      let r = s[0].toUpperCase() + s.slice(1)
        return r.replace(/([a-z])([A-Z])/g, '$1 $2');
    },
    sortOptions() {
      // Create an options list from our fields
      let dim = Object.keys(this.filter_dash.dim).map((f) => {
       var d = this.dimentionopt.filter(t => t.value  == f)
        return { label: d[0].text, key: d[0].value, sortable: true ,_classes: 'dim'};
        // return { label: this.capitalize(f), key: f, sortable: true ,_classes: 'dim'};
      });
      
      // let projection = this.filter_dash.projectionopt.map((f) => {
      //   return { label: this.capitalize(f), key: f, sortable: true };
      // });
      let projection = this.projectionopt_val.map((f) => {
        return { label: this.capitalize(f.text), key: f.value, sortable: true , _classes: 'projection'};
      });
      // let metrics = this.filter_dash.metrics.map((f) => {
      //   return { label: this.capitalize(f), key: f, sortable: true , _classes: 'metrics'};
      // });
      let metrics = this.metricsopt_val.map((f) => {
        return { label: this.capitalize(f.text), key: f.value, sortable: true , _classes: 'metrics'};
      });
      this.fields = []
      this.fields = [
        { label: "Date", key: "date", sortable: true },
        ...dim,
        ...projection,
        ...metrics,
      ];
      this.csvFields = this.fields.map((e) => e.key);
      let b = [];
      for(let e of this.fields) if( b.findIndex(f => f.key == e.key) == -1) b.push(e)
      this.fields = b
      // let p = []
      // var f =  [...new Set(this.fields.map(item => p.push({label: item.label , key : item.key})))];
      return;
    },
    async getDimention() {
      try {
        this.dimentionopt2 = []
        this.dimopt = [];
        let data = { dim: this.dimfilter.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await ManagerService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention1() {
      try {
        this.dimentionopt2_f1 = []
        this.dimopt_f1 = [];
        let data = { dim: this.dimfilter_f1.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await ManagerService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f1.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f1.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f1.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f1.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f1 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f1 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention2() {
      try {
        this.dimentionopt2_f2 = []
        this.dimopt_f2 = [];
        let data = { dim: this.dimfilter_f2.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await ManagerService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f2.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f2.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f2.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f2.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f2 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f2 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention3() {
      try {
        this.dimentionopt2_f3 = []
        this.dimopt_f3 = [];
        let data = { dim: this.dimfilter_f3.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await ManagerService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f3.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f3.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f3.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f3.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f3 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f3 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention4() {
      try {
        this.dimentionopt2_f4 = []
        this.dimopt_f4 = [];
        let data = { dim: this.dimfilter_f4.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await ManagerService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f4.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f4.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f4.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f4.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f4 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f4 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    addUrl_Param(txt,data) {
      var str1 = "?";
      var str3 = data.toLowerCase();
      var str4 = "={" + data + "}";
      
      switch(txt){
        case 'URL':
          for (var i = 0; i < this.url.length; i++) {
            if (this.url.charAt(i) == "?") {
              var str1 = "&";
            }
          }
          this.url_text = str1.concat(str3).concat(str4);
          this.url = this.url.concat(this.url_text);
        break;
        case 'SDK' :
          for (var i = 0; i < this.sdk_url.length; i++) {
            if (this.sdk_url.charAt(i) == "?") {
              var str1 = "&";
            }
          }
          let sdk_url_text = str1.concat(str3).concat(str4);
          this.sdk_url = this.sdk_url.concat(sdk_url_text);
           break;
        case 'zeroMs' :
          for (var i = 0; i < this.zeroms_url.length; i++) {
            if (this.zeroms_url.charAt(i) == "?") {
              // var str1 = "&";
            }
          }
          let zeroms_url_text = str3.concat(str4);
          this.zeroms_url = this.zeroms_url.concat(zeroms_url_text);
        break;
      }
      
      data.toLowerCase();
    },
    copyToClipboard: function(txt) {
      console.log("COPY");
      var textField
      switch(txt){
        case 'URL' :  textField = document.getElementById("campurlforselect2");
          break;
        case 'SDK' : textField = document.getElementById("sdktext");
        break;
        case 'msurl' : textField = document.getElementById("zeromsurl");
        break;
      }
      textField.select();
      textField.focus(); //SET FOCUS on the TEXTFIELD
      document.execCommand("copy");
      Swal.fire({
        title: "Success!",
        text: "Copied!!",
        icon: "success",
        confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
      });
      // this.errorNotify("Success", "URL Copied!!", "success");
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
};
</script>
<style scoped>
.link {
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.f_button{
  margin-top: 35px;
}
</style>
<style>
.table_div{
  overflow: auto; height: 500px;
}
.table_div thead { position: sticky !important; top: 0 !important; z-index: 1 !important; }
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
.table-responsive{
  overflow: unset !important;
}
.dim{
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
.metrics{
  max-width: 110px;
}
.projection{
  max-width: 100px;
}
.filter1:focus-within .multiselect__content-wrapper{
  width: 300px !important;
}
</style>